import styled from "styled-components"

import { Container } from "../../global"

export const Nav = styled.nav`
  padding: ${props => (props.scrolled ? `16px 0` : `24px 0`)};
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
  background: ${props =>
    props.scrolled ? props.theme.color.primary.main : null};
  transition: 0.4s cubic-bezier(0.2, 0.8, 0.2, 1);
`

export const StyledContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
`

export const NavListWrapper = styled.div`
  flex: 1;
  height: 100%;
  ul {
    justify-content: flex-end;
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    height: 100%;

    ${({ mobile }) =>
      mobile &&
      `
        flex-direction: column;
        margin-top: 1em;

        > ${NavItem} {
          margin: 0;
          margin-top: 0.75em;
        }
      `};
  }
`

export const NavItem = styled.li`
  margin: 0 0.75em;
  ${props => props.theme.font.medium};
  ${props => props.theme.font_size.xsmall};
  height: 100%;
  a {
    text-decoration: none;
    display: flex;
    height: 100%;
    justify-items: center;
    align-items: center;
    align-content: center;
    justify-content: center;
    opacity: 0.9;
    color: ${props => props.theme.color.white.dark};
  }
  a:hover {
    color: ${props => props.theme.color.white.regular};
  }
  &.active {
    a {
      opacity: 1;
    }
  }
`

export const MobileMenu = styled.div`
  width: 100%;
  height: 100vh;
  z-index: 1000;
  background: ${props => props.theme.color.regular};
`

export const Brand = styled.div`
  display: flex;
  align-items: center;
  flex: 0;
  position: relative;
  align-self: stretch;
`

export const SimpleLink = styled.a`
  text-decoration: none !important;
  color: inherit;
  &:active {
    a {
      text-decoration: none;
    }
  }
  &:visited {
    text-decoration: none;
  }
`

export const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: ${props => props.theme.screen.xs}) {
    display: none;
  }

  a {
    ${props => props.theme.font.normal};
    ${props => props.theme.font_size.xsmall};
    color: ${props => props.theme.color.white.lessdark};
    border-radius: 4px;
    padding: 10px 16px;
    text-transform: uppercase;
    font-size: 12px;
    border: 1px solid #fff;
    box-sizing: border-box;
  }
  a:hover {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 10px 0px;
    background-color: #fff;
    color: ${props => props.theme.color.primary.dark};
  }
`

export const Mobile = styled.div`
  display: none;

  @media (max-width: ${props => props.theme.screen.xs}) {
    display: flex;
    flex: 0;
  }

  ${props =>
    props.hide &&
    `
    display: flex;
    flex: 1;

    @media (max-width: ${props.theme.screen.xs}) {
      display: none;
    }
  `}
`

// Background blur info
// background-color: ${props => props.scrolled && `rgba(245, 245, 250, .8`};
// box-shadow:  ${props =>
//   props.scrolled &&
//   `0 0 0 1px rgba(0,0,50,.02) inset, 0 1px 1px rgba(0,0,50,.05) inset, 0 2px 4px rgba(0,0,50,.04) inset`};
//   backdrop-filter: ${props => props.scrolled && `blur(15px)`};
